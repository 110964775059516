import React from "react";
import Banner from "../sections/banner";
import Sidebar from "./sidebar";

const BlogElem = ({ banner, children, pageContext, location }) => {
  return (
    <>
      {banner && <Banner data={banner} pageContext={pageContext} location={location} />}
      <section id="blog" className="bg-white px-x py-y-lg">
        <div className="container-team grid grid-cols-1 gap-y-y-lg lg:grid-cols-[1fr_350px] gap-x-[100px]">
          <div>{children}</div>
          <Sidebar />
        </div>
      </section>
    </>
  );
};

export default BlogElem;
