import React from "react";
import convertId from "../../utilities/convert-id";
import { Link, graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";

const Banner = ({ data, pageContext, location }) => {
  const crumbs = pageContext?.breadcrumb?.crumbs

  return (
    <section
      id={convertId(data.name)}
    >
      <div className="banner section relative h-[353px] sm:h-[453px] md:h-[553px] lg:h-[653px] bg-blue-2">
        <CmsImage
          image={data.background}
          className={`!absolute inset-0 ${!data.hideOverlay && "opacity-30"}`}
        />
        <div
          className={`absolute ${
            data.centerText
              ? "inset-0 flex flex-col justify-center items-center"
              : "inset-x-0 bottom-0"
          } px-x lg:px-[100px] pb-y lg:pb-[51px]`}
        >
          {data.subTitle && (
            <Content className="typo-banner-sub-title mb-[5px]">
              {data.subTitle}
            </Content>
          )}
          <Content className="typo-slider-title">{data.title}</Content>
        </div>
      </div>
      {crumbs?.length > 0 && (
        <div className="breadcrumbs bg-white p-[20px] pb-0 sm:pt-[20px] sm:px-[50px]">
          {crumbs.map((crumb, index) => {
              let originalCrumbLabel = crumb.crumbLabel
              let customCrumbLabel = originalCrumbLabel.toLowerCase().split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');


              if (!isNaN(customCrumbLabel)) {
                customCrumbLabel = 'Page ' + customCrumbLabel;
              }
              return (
                <Link key={index} to={crumb.pathname} className="text-green-1 hover:text-blue-2 after:content-['_/_'] after:px-4 after:text-blue-2 last:pointer-events-none last:text-gray-3  last:after:content-none"  disabled={index === crumbs.length-1}>{customCrumbLabel}</Link>
              )
          })}
        </div>    
      )}
    </section>
  );
};

export const query = graphql`
  fragment Banner on WpPage_Sections_Sections_Banner {
    fieldGroupName
    name
    centerText
    subTitle
    title
    background {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        extension
        publicURL
      }
    }
    hideOverlay
  }
  fragment ReusableBanner on WpReusableSection_Sections_Sections_Banner {
    fieldGroupName
    name
    centerText
    subTitle
    title
    background {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        extension
        publicURL
      }
    }
    hideOverlay
  }
  fragment ProjectBanner on WpProject_Sections_Sections_Banner {
    fieldGroupName
    name
    centerText
    subTitle
    title
    background {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        extension
        publicURL
      }
    }
    hideOverlay
  }
`;

export default Banner;
