import React from "react";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";
import Pagination from "../utilities/pagination";

const Posts = ({ posts, totalPages, currentPage, baseUri, pageContext, location }) => {
  return (
    <>
      {posts.edges.map(({ node: post }, i) => (
        <div key={post.id}>
          {i === 0 ? (
            <div className="mb-[50px]">
              {post.featuredImage && (
                <Link className="block mb-y-sm" to={post.uri}>
                  <CmsImage image={post.featuredImage.node} />
                </Link>
              )}
              <h3 className="content-post-title typo-post-title">
                <Link to={post.uri}>{post.title}</Link>
              </h3>
              <Content>{post.excerpt}</Content>
            </div>
          ) : (
            <div className={`
              grid grid-cols-1 gap-x-x lg:gap-x-x-sm xl:gap-x-x gap-y-y-sm mb-[50px]
              ${post.featuredImage ? "md:grid-cols-[400fr_330fr]" : ""}
            `}>
              {post.featuredImage && (
                <div>
                  <Link to={post.uri}>
                    <CmsImage image={post.featuredImage.node} />
                  </Link>
                </div>
              )}
              <div className="
                relative
                after:absolute
                after:bottom-0
                after:inset-x-0
                after:bg-[linear-gradient(hsla(0,0%,100%,0),#fff)]
                after:z-10
                after:h-[100px]
              ">
                <h3 className="content-post-title typo-h9">
                  <Link to={post.uri}>{post.title}</Link>
                </h3>
                <Content>{post.excerpt}</Content>
              </div>
            </div>
          )}
        </div>
      ))}
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        baseUri={baseUri}
        endId="blog"
      />
    </>
  );
};

export default Posts;
