import React from "react";
import { Helmet } from "react-helmet";

const Seo = ({ page, themeSettings, title, currentPage }) => {
  let metaDesc = page?.metaDescription;
  const hasPage = currentPage && currentPage !== 1;

  if (metaDesc && hasPage) {
    metaDesc = metaDesc + " - Page " + currentPage;
  }

  return (
    <Helmet>
      <title>
        {page?.metaTitle ? page?.metaTitle : title}
        {" - "}
        {hasPage ? `Page ${currentPage} - ` : ""}
        {themeSettings.siteMetaTitle}
      </title>
      {metaDesc && <meta name="description" content={metaDesc} />}
      {(page?.pageCssOverrides) && (
        <style name="page-css">
          {page?.pageCssOverrides}
        </style>
      )}
      {(themeSettings?.customCssOverrides) && (
        <style name="custom-css-overrides">
          {themeSettings.customCssOverrides}
        </style>
      )}
      {(themeSettings?.customCssClasses) && (
        <style name="custom-css-classes">
          {themeSettings.customCssClasses}
        </style>
      )}
      {(page?.pageSchema) && (
        <script type="application/ld+json">
          {page.pageSchema}
        </script>
      )}
    </Helmet>
  );
};

export default Seo;
