import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

const Sidebar = () => {
  const {
    allRecentPosts: { edges: recentPosts },
    allWpCategory: { nodes: categories },
  } = useStaticQuery(
    graphql`
      query Sidebar {
        allRecentPosts: allWpPost(
          sort: { order: DESC, fields: date }
          limit: 3
        ) {
          edges {
            node {
              id
              title
              uri
            }
          }
        }
        allWpCategory {
          nodes {
            id
            name
            slug
          }
        }
      }
    `
  );

  return (
    <aside>
      <div className="blog-sidebar-inside">
        <div>
          <h6 className="typo-h6 mb-[10px]">Recent Posts</h6>
          <ul className="space-y-[10px]">
            {recentPosts.map(({ node: post }) => {
              return (
                <li key={post.id}>
                  <Link className="blog-sidebar-recent-link" to={post.uri}>
                    {post.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <h6 className="typo-h6">Keep up with us</h6>
          <p className="typo-team-intro-content mb-[10px]">
            Sign up for our newsletter.
          </p>
          <a href="#newsletter-signup" className="button">
            Signup
          </a>
        </div>
        <div>
          <h6 className="typo-h6 mb-[10px]">Categories</h6>
          <ul>
            {categories.map((category) => {
              return (
                <li className="flex" key={category.id}>
                  <Link
                    className="blog-sidebar-category-link"
                    to={"/" + category.slug + "/"}
                  >
                    {category.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
