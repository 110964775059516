import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Posts from "../components/blog/posts";
import Seo from "../components/utilities/seo";

const Blog = ({
  data: {
    allWpPost: posts,
    wpPage: page,
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: { totalPages, currentPage, baseUri },
  pageContext,
  location,
}) => {
  const banner = {
    name: "blog-banner",
    background: page.blog.background,
    subTitle: page.blog.subTitle,
    title: page.blog.title,
    pageContext: pageContext,
    location: location,
  };

  return (
    <>
      <Seo page={page.page} themeSettings={themeSettings} title={page.title} />
      <BlogElem banner={banner} pageContext={pageContext} location={location}>
        <Posts
          posts={posts}
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
        />
      </BlogElem>
    </>
  );
};

export const pageQuery = graphql`
  query Blog($id: String!, $perPage: Int!, $skipItems: Int!) {
    allWpPost(
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
    ) {
      edges {
        node {
          id
          title
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760, height: 427)
                }
                extension
                publicURL
              }
            }
          }
          excerpt
        }
      }
    }
    wpPage(id: { eq: $id }) {
      blog {
        subTitle
        title
        background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
        pageSchema
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          customCssClasses
        }
      }
    }
  }
`;

export default Blog;
