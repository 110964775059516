import React from "react";
import Link from "./link";
import { Helmet } from "react-helmet";

const Pagination = ({ totalPages, currentPage, baseUri, endId }) => {
  const PaginationLink = ({ page, children, endId, currentPage }) => (
    <li key={page}>
      <Link
        className={`pagination ${page === currentPage ? "active" : ""}`}
        to={
          baseUri + (page !== 1 ? page + "/" : "") + (endId ? "#" + endId : "")
        }
      >
        {children}
      </Link>
    </li>
  );

  const totalPagesArray = [...Array(totalPages).keys()];

  const getRelUrl = (page) => {
    page = page === 1 ? "" : page + "/";

    return process.env.GATSBY_URL + baseUri + page;
  };

  const prevPage = currentPage - 1,
    nextPage = currentPage + 1;

  return (
    <>
      <ul className="flex -space-x-[2px] flex-wrap">
        {currentPage !== 1 && (
          <PaginationLink page={currentPage - 1} endId={endId}>
            Previous
          </PaginationLink>
        )}
        {totalPagesArray.map((page) => {
          page++;
          return (
            <PaginationLink
              key={page}
              page={page}
              endId={endId}
              currentPage={currentPage}
            >
              {page}
            </PaginationLink>
          );
        })}
        {currentPage !== totalPages && (
          <PaginationLink page={currentPage + 1} endId={endId}>
            Next
          </PaginationLink>
        )}
      </ul>
      <Helmet>
        {prevPage !== 0 && <link rel="prev" href={getRelUrl(prevPage)} />}
        {nextPage <= totalPages && (
          <link rel="next" href={getRelUrl(nextPage)} />
        )}
      </Helmet>
    </>
  );
};

export default Pagination;
